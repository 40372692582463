<template>
  <div>
    <div
      class="bg-img relative"
      :style="`background-image: url('${imageUrl}');`"
    >
      <div class="absolute inset-0 pt-6 xl:p-20 flex flex-col justify-start xl:flex-row xl:justify-between">
        <div
          class="flex-none pb-4 xl:flex-auto text-base md:text-2xl text-center lg:text-3xl xl:text-left xl:text-6xl pl-0 xl:pl-20 leading-normal xl:leading-tight font-bold uppercase display-font display-font--blue display-font-large"
          v-html="cmsBlock.decoded_content.heading"
        ></div>
        <div
          class="flex-none xl:flex-auto text-base md:text-lg md:leading-relaxed lg:text-2xl right-col-leading xl:pl-20 display-font--blue text-center xl:text-left body-text"
          v-html="cmsBlock.decoded_content.body"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TwoTextColumnsImageBackground",
  props: {
    cmsBlock: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    imageUrl() {
      let html = document.querySelector("html");
      if (html.classList.contains("webp")) {
        if (this.cmsBlock.decoded_content.webp_image_2x_url) {
          return this.cmsBlock.decoded_content.webp_image_2x_url;
        }
        return this.cmsBlock.decoded_content.webp_image_url;
      } else {
        if (this.cmsBlock.decoded_content.jpg_image_2x_url) {
          return this.cmsBlock.decoded_content.jpg_image_2x_url;
        }
        return this.cmsBlock.decoded_content.jpg_image_url;
      }
    }
  }
};
</script>

<style scoped>
.body-text strong {
  @apply font-bold;
  font-family: "Playfair Display", "Times New Roman", serif;
}

.bg-img {
  height: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 700px;
  padding-bottom: 400px;
}

@media (min-width: 700px) {
  .bg-img {
    background-size: 900px;
    padding-bottom: 500px;
  }
}

@media (min-width: 900px) {
  .bg-img {
    background-size: 1100px;
    padding-bottom: 600px;
  }
}

@media (min-width: 1100px) {
  .bg-img {
    background-size: 1300px;
    padding-bottom: 700px;
  }
}

@media (min-width: 1280px) {
  .bg-img {
    background-size: cover;
    padding-bottom: 92%;
  }
}

.display-font {
  font-family: "Playfair Display", "Times New Roman", serif;
}

.display-font--blue {
  color: #2c4b68;
}

@screen lg {
  .right-col-leading {
    line-height: 2.5;
  }
}
</style>
