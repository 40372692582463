<template>
  <div>
    <div
      class="justify-start flex flex-row flex-no-wrap items-center my-10 relative"
      v-if="cmsBlock.decoded_content.graph_data && cmsBlock.decoded_content.graph_data.length > 0"
    >
      <button
        @click="togglePlay()"
        class="flex-none player-control bg-red-500 hover:bg-red-400 rounded-full mr-4 lg:mr-10 duration-500 ease-in-out outline-none focus:outline-none text-center"
      >
        <img
          v-if="!play"
          src="../../assets/img/icons/icon-play.svg"
          class="mx-auto"
        />
        <img
          v-else
          src="../../assets/img/icons/icon-pause.svg"
          class="mx-auto"
        />
      </button>
      <div
        class="w-full"
        @click="seek"
        ref="graphWrapper"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          :viewBox="'0 0 ' + viewBoxWidth + ' ' + viewBoxHeight"
          preserveAspectRatio="none"
          height="20px"
          width="100%"
          class="my-10"
        >
          <rect
            y="0"
            x="0"
            :height="viewBoxHeight"
            :width="viewBoxWidth"
            style="
              opacity: 1;
              fill: #000000;
              fill-opacity: 0.1;
              stroke: #00ff00;
              stroke-width: 0;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
          />
          <rect
            y="0"
            x="0"
            :height="viewBoxHeight"
            :width="bufferProgress + '%'"
            style="
              opacity: 1;
              fill: #fd656f;
              fill-opacity: 0.2;
              stroke: #000000;
              stroke-width: 0;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            class="progress-bar"
          />
          <rect
            y="0"
            x="0"
            :height="viewBoxHeight"
            :width="progress + '%'"
            style="
              opacity: 1;
              fill: #aaaaaa;
              fill-opacity: 1;
              stroke: #000000;
              stroke-width: 0;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
            class="progress-bar"
          />

          <path
            :d="path"
            style="
              opacity: 1;
              fill: #fefbf2;
              fill-opacity: 1;
              stroke: #fefbf2;
              stroke-width: 0;
              stroke-miterlimit: 4;
              stroke-dasharray: none;
              stroke-opacity: 1;
            "
          />
        </svg>
      </div>
      <span
        v-if="duration"
        class="absolute top-0 right-0 h-10 w-30"
      >
        {{ duration }}
      </span>
    </div>
  </div>
</template>

<script>
import { Howl } from "howler";
import SecondsToTime from "@/services/SecondsToTime";

export default {
  name: "AudioTrack",
  props: {
    cmsBlock: {
      type: Object,
      required: true
    },
    muted: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      viewBoxHeight: 100,
      viewBoxWidth: 1000,
      sound: null,
      play: false,
      progress: 0,
      bars: 100,
      refresher: null,
      bufferProgress: 0
    };
  },
  beforeUnmount() {
    if (this.refresher) {
      window.clearInterval(this.refresher);
    }
    if (this.sound) {
      this.sound.stop();
    }
  },
  beforeRouteLeave() {
    if (this.sound) {
      this.sound.stop();
    }
  },
  watch: {
    muted(newValue) {
      if (this.sound) {
        this.sound.mute(newValue);
      }
    }
  },
  computed: {
    refreshMilliseconds() {
      return (this.cmsBlock.decoded_content.duration_in_seconds * 1000) / (this.bars * 4);
    },
    path() {
      let maxPeek = 0;
      let sampleCount = this.cmsBlock.decoded_content.graph_data.length;

      this.cmsBlock.decoded_content.graph_data.forEach((gd) => {
        if (gd > maxPeek) {
          maxPeek = gd;
        }
      });

      //we want our max peeks to hit the height of our svg
      let heightNormalizer = this.viewBoxHeight / maxPeek;
      let widthNormalizer = this.viewBoxWidth / sampleCount;
      let gap = widthNormalizer / 4;

      let path = "M 0 0 H " + this.viewBoxWidth + " V " + this.viewBoxHeight + " H 0 V 0 z ";
      let startX;
      let toX;
      let toY;

      for (let i = 0; i < sampleCount; i++) {
        startX = parseFloat(i * widthNormalizer) + parseFloat(gap);
        toX = startX + parseFloat(widthNormalizer) - parseFloat(gap);
        toY = this.viewBoxHeight - parseFloat(this.cmsBlock.decoded_content.graph_data[i] * heightNormalizer);
        path += `M ${startX} ${this.viewBoxHeight}`;
        path += ` H ${toX}`;
        path += ` V ${toY}`;
        path += ` H ${startX}`;
        path += ` V ${this.viewBoxHeight}`;
        path += " z ";
      }

      return path;
    },
    duration() {
      let secondsToTime = new SecondsToTime(this.cmsBlock.decoded_content.duration_in_seconds);
      return secondsToTime.getTime();
    }
  },
  methods: {
    togglePlay() {
      this.play = !this.play;
      if (this.play) {
        if (!this.sound) {
          this.initializeSound();
        }
        this.sound.play();
        this.refresher = window.setInterval(() => {
          let seek = this.sound.seek() || 0;
          this.progress = (seek / this.sound.duration()) * 100 || 0; //+= 0.25;
        }, this.refreshMilliseconds);
      } else {
        this.sound.pause();
        if (this.refresher) {
          window.clearInterval(this.refresher);
        }
      }
    },
    seek(event) {
      if (!this.sound) {
        this.initializeSound();
      }

      let clickX = event.pageX;
      let rect = this.$refs.graphWrapper.getBoundingClientRect();
      let offset = clickX - rect.left;
      let offsetPercentage = 0;
      if (offset) {
        offsetPercentage = (offset / rect.width) * 100;
      }

      let seekSeconds = (this.cmsBlock.decoded_content.duration_in_seconds / 100) * offsetPercentage;

      this.progress = offsetPercentage;
      this.sound.seek(seekSeconds);
    },
    initializeSound() {
      this.sound = new Howl({
        src: [this.cmsBlock.decoded_content.audio_url],
        html5: true,
        preload: true
      });

      let that = this;
      const loadHandler = function () {
        const node = that.sound._sounds[0]._node;
        node.addEventListener("progress", () => {
          const duration = that.sound.duration();
          if (duration > 0) {
            for (let i = 0; i < node.buffered.length; i++) {
              if (node.buffered.start(node.buffered.length - 1 - i) < node.currentTime) {
                that.bufferProgress = (node.buffered.end(node.buffered.length - 1 - i) / duration) * 100;
                break;
              }
            }
          }
        });
      };

      this.sound.on("load", loadHandler);

      this.sound.on("end", function () {
        that.sound.stop();
        if (that.refresher) {
          window.clearInterval(that.refresher);
        }
        that.play = false;
        that.progress = 0;
        that.bufferProgress = 0;
      });
    }
  }
};
</script>

<style scoped>
.progress-bar {
  transition: all 0.25s linear;
}

.player-control {
  width: 50px;
  height: 50px;
}

@media (min-width: 1024px) {
  .player-control {
    width: 80px;
    height: 80px;
  }
}
</style>
