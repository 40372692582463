<template>
  <div class="home content-bg min-h-screen public-container pb-32">
    <div class="-my-10 sm:-my-20">
      <component
        v-for="(cmsBlock, index) in cmsBlocks"
        :is="cmsBlock.type"
        :key="cmsBlock.id"
        :cms-block="cmsBlock"
        :class="getMargins(index)"
      />
    </div>
  </div>
</template>

<script>
import CmsBlocksMixin from "@/components/blocks/CmsBlocksMixin.js";
import ApiService from "@/services/ApiService";

export default {
  name: "Home",
  mixins: [CmsBlocksMixin],
  methods: {
    fetchBlocks() {
      ApiService.getCmsPage("home")
        .then((response) => {
          this.cmsBlocks = response.data.cmsBlocks;
        })
        .catch(() => {});
    },
    getMargins(index) {
      if (index === 0) {
        return "my-10 sm:my-20";
      }

      if (this.cmsBlocks[index].type !== "CircleOfLife" && this.cmsBlocks[index - 1].type !== "CircleOfLife") {
        return "my-10 sm:my-20";
      }
    }
  }
};
</script>
