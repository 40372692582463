export default {
  namespaced: true,
  state: {
    siteVersion: null
  },
  mutations: {
    setSiteVersion(state, v) {
      state.siteVersion = v;
    }
  }
};
