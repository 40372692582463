<template>
  <div class="text-center relative pt-20 overflow-hidden main-container">
    <img
      src="/storage/humming_bird.png"
      class="main-image mx-auto inline-block"
    />
    <p
      class="text-lg md:text-2xl font-bold mb-10 px-4 md:px-0"
      v-html="cmsBlock.decoded_content.question"
    ></p>
    <router-link
      :to="surveyRoute"
      class="mx-auto inline-block"
    >
      <red-dot />
    </router-link>
    <svg
      width="248"
      height="480"
      viewBox="0 0 248 480"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="absolute z-10 left-mountain"
    >
      <path
        opacity="0.03"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1 0.80249L248 493.802H-246L1 0.80249Z"
        fill="#AE6B00"
      />
    </svg>
    <svg
      width="318"
      height="296"
      viewBox="0 0 318 296"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="absolute z-10 middle-mountain"
    >
      <path
        opacity="0.03"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M159 0.802551L318 318.803H0L159 0.802551Z"
        fill="#AE6B00"
      />
    </svg>
    <svg
      width="249"
      height="448"
      viewBox="0 0 249 448"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="absolute z-10 right-mountain"
    >
      <path
        opacity="0.03"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M246.753 0L493.432 493H0.0742188L246.753 0Z"
        fill="#AE6B00"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "StartSurvey",
  props: {
    cmsBlock: {
      type: Object,
      required: true
    }
  },
  computed: {
    surveyRoute() {
      let nextQuestionIndex = 1;

      if (!this.$store.state.survey || !this.$store.state.survey.questions || !this.$store.state.survey.answers) {
        return {
          name: "SurveyQuestion",
          params: { questionIndex: nextQuestionIndex }
        };
      }

      if (this.$store.state.survey.answers.length) {
        nextQuestionIndex = this.$store.state.survey.answers.length + 1;
      }
      if (nextQuestionIndex <= this.$store.state.survey.questions.length) {
        return {
          name: "SurveyQuestion",
          params: { questionIndex: nextQuestionIndex }
        };
      } else {
        return { name: "SurveyEmail" };
      }
    }
  }
};
</script>

<style scoped>
.main-container {
  min-height: 650px;
}

.main-image {
  max-width: 200px;
}

.left-mountain {
  left: 0;
  bottom: -5px;
  width: 100px;
  height: 200px;
}

.middle-mountain {
  left: 20px;
  bottom: -10px;
  width: 120px;
  height: 120px;
}

.right-mountain {
  right: 0;
  bottom: -20px;
  width: 100px;
  height: 200px;
}

@media (min-width: 768px) {
  .main-container {
    min-height: 600px;
  }

  .left-mountain {
    left: 0;
    bottom: -5px;
    width: 200px;
    height: 400px;
  }

  .middle-mountain {
    left: 20px;
    bottom: -10px;
    width: 240px;
    height: 240px;
  }

  .right-mountain {
    right: 0;
    bottom: -20px;
    width: 200px;
    height: 400px;
  }
}
</style>
