import ApiService from "@/services/ApiService";

/**
 * checkForNotifications should be false when they first open the map,
 *  but set to true after they've finished a days content
 */

export default {
  namespaced: true,
  state: {
    weeks: [],
    week: null,
    dayCompleted: null,
    currentWeekNumber: 1,
    chapters: null
  },
  getters: {
    getRelatedContentForPayload: (state) => (payload) => {
      if (!state.week || !state.week.days) {
        return null;
      }

      return state.week.days.find((day) => parseInt(day.day_number, 10) === parseInt(payload.day, 10)).related_content;
    },
    getDaysContentForPayload: (state) => (payload) => {
      return state.week.days.find((day) => parseInt(day.day_number, 10) === parseInt(payload.day, 10));
    },
    getCurrentWeekNumber: (state) => {
      return state.currentWeekNumber;
    },
    getWeekByWeekNumber: (state) => (weekNumber) => {
      let index = state.weeks.findIndex((w) => {
        return parseInt(w.week_number, 10) === parseInt(weekNumber, 10);
      });

      if (index === -1) {
        return null;
      }

      return state.weeks[index];
    }
  },
  mutations: {
    reset(state) {
      state.weeks = [];
      state.week = null;
      state.checkForNotifications = false;
      state.currentWeekNumber = 1;
      state.chapters = null;
    },
    favouriteDaysContentForPayload(state, payload) {
      let dayIndex = state.week.days.findIndex((day) => parseInt(day.day_number, 10) === parseInt(payload.day, 10));
      state.week.days[dayIndex].course_content.favourites.push(payload.favourite);
    },
    disfavourDaysContentForPayload(state, payload) {
      let dayIndex = state.week.days.findIndex((day) => parseInt(day.day_number, 10) === parseInt(payload.day, 10));

      if (dayIndex !== -1) {
        state.week.days[dayIndex].course_content.favourites = [];
      }
    },
    disfavourDaysContentForWeekAndDay(state, payload) {
      let dayIndex = state.week.days.findIndex((day) => parseInt(day.day_number, 10) === parseInt(payload.day, 10));

      if (dayIndex !== -1) {
        state.week.days[dayIndex].course_content.favourites = [];
      }

      let index = state.weeks.findIndex((w) => {
        return parseInt(w.week_number, 10) === parseInt(payload.week, 10);
      });

      if (index === -1) {
        return null;
      }

      dayIndex = state.weeks[index].days.findIndex((day) => parseInt(day.day_number, 10) === parseInt(payload.day, 10));

      if (dayIndex !== -1) {
        state.weeks[index].days[dayIndex].course_content.favourites = [];
      }
    },

    setDayCompleted(state, payload) {
      state.dayCompleted = payload;
    },
    setWeek(state, week) {
      state.week = week;
    },
    setWeeks(state, weeks) {
      state.weeks = weeks;
    },
    addWeekToWeeks(state, week) {
      let index = state.weeks.findIndex((w) => {
        return parseInt(w.week_number, 10) === parseInt(week.week_number, 10);
      });

      if (index === -1) {
        state.weeks.push(week);
      } else {
        state.weeks[index] = week;
      }
    },
    setChapters(state, chapters) {
      state.chapters = chapters;
    },
    setCurrentWeekNumber(state, weekNumber) {
      state.currentWeekNumber = weekNumber;
    },
    setWeeksWeekNumberToWeek(state, weekNumber) {
      let index = state.weeks.findIndex((w) => {
        return parseInt(w.week_number, 10) === parseInt(weekNumber, 10);
      });

      if (index !== -1) {
        state.week = state.weeks[index];
      }
    }
  },
  actions: {
    dayComplete(context, payload) {
      return new Promise((resolve, reject) => {
        ApiService.getCsrf()
          .then(() => {
            ApiService.dayComplete(payload)
              .then((response) => {
                //if they've just moved on to a new week then we'll have got the new week back
                //so we need to refetch the previous week to make sure the status of it's last
                //day gets updated
                if (payload.week !== response.data.week.week_number) {
                  context
                    .dispatch("fetchWeekByNumber", payload.week)
                    .then(() => {})
                    .catch(() => {});
                }
                context.commit("setWeek", response.data.week);
                context.commit("addWeekToWeeks", response.data.week);
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getChapters(context) {
      return new Promise((resolve, reject) => {
        ApiService.getChapters()
          .then((response) => {
            context.commit("setChapters", response.data.chapters);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    primeChapters(context) {
      return new Promise((resolve, reject) => {
        if (context.state.chapters) {
          resolve();
        }

        context
          .dispatch("getChapters")
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchWeekByNumber(context, weekNumber) {
      return new Promise((resolve, reject) => {
        ApiService.getWeekByWeekNumber(weekNumber)
          .then((response) => {
            context.commit("addWeekToWeeks", response.data.week);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    primeWeekByNumber(context, weekNumber) {
      return new Promise((resolve, reject) => {
        let index;

        if (context.state.weeks.length > 0) {
          index = context.state.weeks.findIndex((w) => {
            return parseInt(w.week_number, 10) === parseInt(weekNumber, 10);
          });
        } else {
          index = -1;
        }

        if (index !== -1) {
          resolve();
        } else {
          context
            .dispatch("fetchWeekByNumber", weekNumber)
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    primeSelectedWeek(context, weekNumber) {
      return new Promise((resolve, reject) => {
        ApiService.getWeekByWeekNumber(weekNumber)
          .then((response) => {
            context.commit("setWeek", response.data.week);
            context.commit("setCurrentWeekNumber", response.data.week.week_number);
            context.commit("addWeekToWeeks", response.data.week);
            context.commit("user/setTutorialStatus", response.data.tutorialWatched, { root: true });
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    primeCurrentWeek(context) {
      return new Promise((resolve, reject) => {
        ApiService.getCurrentWeek()
          .then((response) => {
            context.commit("setWeek", response.data.week);
            context.commit("setCurrentWeekNumber", response.data.week.week_number);
            context.commit("addWeekToWeeks", response.data.week);
            context.commit("user/setTutorialStatus", response.data.tutorialWatched, { root: true });
            context.commit("user/setCourseStart", response.data.courseStart, { root: true });
            context.commit("user/setIntroVideoStatus", response.data.introVideoWatched, { root: true });
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }
};
