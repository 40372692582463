<template>
  <div class="w-4/5 lg:w-6/7 xl:w-5/6 mx-auto flex flex-col lg:flex-row justify-between flex-wrap">
    <div class="flex-1 mb-8 lg:mb-0">
      <h2
        class="text-lg sm:text-3xl font-bold w-3/4 pr-0 md:pr-20 mb-4 leading-relaxed"
        v-html="cmsBlock.decoded_content.introHeading"
      ></h2>
      <p
        class="font-medium leading-loose pr-0 md:pr-20 mb-8"
        v-html="cmsBlock.decoded_content.introBody"
      ></p>
      <div class="flex flex-col sm:flex-row">
        <div class="w-full mb-4 sm:mb-0 sm:wd-1/2 pr-0 sm:pr-2 box-border">
          <h3
            class="text-lg font-bold mb-2"
            v-html="cmsBlock.decoded_content.emailHeading"
          ></h3>
          <p
            class="font-medium"
            v-html="cmsBlock.decoded_content.emailBody"
          ></p>
        </div>
        <div class="w-full sm:wd-1/2 pr-0 sm:pl-2 box-border">
          <h3
            class="text-lg font-bold mb-2"
            v-html="cmsBlock.decoded_content.callHeading"
          ></h3>
          <p
            class="font-medium"
            v-html="cmsBlock.decoded_content.callBody"
          ></p>
        </div>
      </div>
    </div>

    <form
      class="flex-1 needs-validation relative"
      id="contact-form"
      :class="{ 'was-validated': submitPressed }"
      method="post"
      novalidate
    >
      <transition name="fade">
        <div
          v-if="messageSuccess"
          class="p-20 flex flex-row items-center text-center absolute inset-0 z-10 bg-white"
        >
          <p
            class="text-3xl font-bold font-black opacity-50"
            v-html="cmsBlock.decoded_content.successMessage"
          ></p>
        </div>
      </transition>
      <div class="flex flex-col sm:flex-row">
        <div class="w-full mb-4 sm:mb-0 sm:wd-1/2 pr-0 sm:pr-2 box-border">
          <input
            type="text"
            placeholder="Full Name"
            class="appearance-none block w-full text-gray-700 bg-red-200 p-3"
            maxlength="255"
            required
            v-model="fullname"
          />
          <p
            class="text-red-500 hidden"
            :class="{
              'failed-server-validation': fullnameServerInvalid
            }"
          >
            Please provide a Full Name
          </p>
        </div>
        <div class="w-full sm:wd-1/2 pr-0 sm:pl-2 box-border">
          <input
            type="email"
            placeholder="Email"
            class="appearance-none block w-full text-gray-700 bg-red-200 p-3"
            maxlength="255"
            required
            v-model="email"
          />
          <p
            class="text-red-500 hidden"
            :class="{
              'failed-server-validation': emailServerInvalid
            }"
          >
            Please provide an Email address
          </p>
        </div>
      </div>

      <div class="mt-4 mb-0 p-0">
        <textarea
          placeholder="Write Message"
          class="w-full bg-red-200 p-3 appearance-none box-border block"
          maxlength="1000"
          required
          rows="10"
          v-model="message"
        >
        </textarea>
        <p
          class="text-red-500 hidden"
          :class="{
            'failed-server-validation': messageServerInvalid
          }"
        >
          Please write a message
        </p>
      </div>
      <button
        class="mt-4 transition duration-500 ease-in-out bg-red-500 hover:bg-red-400 text-white font-bold py-3 px-8 rounded-full flex-none text-center outline-none focus:outline-none"
        @click.prevent.stop="send()"
        :disabled="disableForm"
      >
        <span v-if="!disableForm">Send Message</span><span v-else>Sending</span>
      </button>
    </form>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";

export default {
  name: "ContactForm",
  props: {
    cmsBlock: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      fullname: "",
      email: "",
      message: "",
      submitPressed: false,
      errors: {
        fullname: "",
        email: "",
        message: ""
      },
      processing: false,
      messageSuccess: false
    };
  },
  computed: {
    fullnameServerInvalid() {
      return !!(this.errors && this.errors.fullname !== "");
    },
    emailServerInvalid() {
      return !!(this.errors && this.errors.email !== "");
    },
    messageServerInvalid() {
      return !!(this.errors && this.errors.message !== "");
    },
    disableForm() {
      return this.processing;
    }
  },
  watch: {
    fullname(newFullname, oldFullname) {
      if (this.fullnameServerInvalid) {
        if (newFullname !== oldFullname && newFullname.length > 0) {
          this.errors.fullname = "";
        }
      }
    },
    email(newEmail, oldEmail) {
      if (this.emailServerInvalid) {
        if (newEmail !== oldEmail && newEmail.length > 0) {
          this.errors.email = "";
        }
      }
    },
    message(newMessage, oldMessage) {
      if (this.messageServerInvalid) {
        if (newMessage !== oldMessage && newMessage.length > 0) {
          this.errors.message = "";
        }
      }
    }
  },
  methods: {
    send() {
      let form = document.getElementById("contact-form");
      this.submitPressed = true;
      if (form.checkValidity() === false) {
        return;
      }

      this.submitPressed = false;
      this.processing = true;
      this.errors.fullname = "";
      this.errors.email = "";
      this.errors.message = "";

      let params = {
        fullname: this.fullname,
        email: this.email,
        message: this.message
      };

      ApiService.getCsrf()
        .then(() => {
          ApiService.postContactForm(params)
            .then(() => {
              this.messageSuccess = true;
              this.processing = false;
            })
            .catch((error) => {
              this.processing = false;
              if (error && error.response && error.response.data && error.response.data.errors) {
                this.errors.fullname = error.response.data.errors.fullname ? error.response.data.errors.fullname : "";
                this.errors.email = error.response.data.errors.email ? error.response.data.errors.email : "";
                this.errors.message = error.response.data.errors.message ? error.response.data.errors.message : "";
              }
            });
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped>
.was-validated input:invalid,
.was-validated textarea:invalid {
  border: 1px solid theme("colors.red.500");
}

.was-validated input:valid,
.was-validated textarea:valid {
  border: 1px solid theme("colors.green.500");
}

.was-validated input:invalid + .hidden,
.was-validated textarea:invalid + .hidden {
  display: inline-block !important;
}

.failed-server-validation {
  display: inline-block !important;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.fade-enter-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
