<template>
  <div>
    <carousel wrap-around>
      <slide
        v-for="slide in cmsBlock.decoded_content.slides"
        :key="slide.name"
        :items-to-show="1"
        class="pb-20"
      >
        <div
          class="flex flex-col lg:flex-row justify-between items-center w-3/4 lg:w-5/6 slide-content-bg shadow-lg lg:shadow-2xl"
        >
          <div class="flex-auto lg:flex-1 p-8 md:px-10 lg:px-20">
            <svg
              width="263"
              height="263"
              viewBox="0 0 263 263"
              fill="none"
              class="mx-auto"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M98.0741 8.44623C25.4653 46.1581 -19.2807 152.189 9.27983 214.495C37.8404 
                                276.801 264.708 252.978 262.384 165.462C260.06 77.9469 170.683 -29.2657 
                                98.0741 8.44623Z"
                fill="#2E2E2E"
                fill-opacity="0.1"
              />
              <mask
                id="mask0"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="263"
                height="263"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M98.0741 8.44623C25.4653 46.1581 -19.2807 152.189 9.27983 214.495C37.8404 
                                    276.801 264.708 252.978 262.384 165.462C260.06 77.9469 170.683 -29.2657 
                                    98.0741 8.44623Z"
                  fill="white"
                  fill-opacity="1"
                />
              </mask>
              <g mask="url(#mask0)">
                <g style="mix-blend-mode: lighten">
                  <rect
                    y="-18.0505"
                    width="262.658"
                    height="350.474"
                    fill="url(#pattern0)"
                  />
                </g>
              </g>
              <defs>
                <pattern
                  id="pattern0"
                  patternContentUnits="objectBoundingBox"
                  width="1"
                  height="1"
                >
                  <use
                    xlink:href="#image0"
                    transform="scale(0.000833333 0.000625)"
                  />
                </pattern>
              </defs>
              <foreignObject
                width="263"
                height="263"
                id="image0"
                mask="url(#mask0)"
              >
                <picture>
                  <img :src="slide.jpg_image_url" />
                </picture>
              </foreignObject>
            </svg>

            <h2 class="text-center text-3xl font-bold my-4">
              {{ slide.name }}
            </h2>
            <p class="text-center text-base">{{ slide.profession }}</p>
          </div>
          <div
            class="flex-auto lg:flex-1 p-8 md:p-10 lg:p-20 text-left testimonial"
            v-html="slide.body"
          ></div>
        </div>
      </slide>

      <template #addons>
        <navigation />
      </template>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide, Navigation } from "vue3-carousel";

export default {
  name: "Testimonials",
  components: {
    Carousel,
    Slide,
    Navigation
  },
  props: {
    cmsBlock: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="postcss">
.slide-content-bg {
  background-color: #faf6ed;
}

.testimonial h2 {
  @apply text-lg;
  @apply mb-2;
  @apply font-bold;
}
@screen lg {
  .testimonial h2 {
    @apply text-2xl;
    @apply mb-4;
  }
}

.testimonial p {
  @apply mb-8;
  @apply text-lg;
  @apply text-black;
  @apply text-opacity-50;
  @apply font-medium;
}
@screen lg {
  .testimonial p {
    @apply mb-8;
    @apply text-lg;
  }
}

.carousel {
  position: relative;
  text-align: center;
  box-sizing: border-box;
}

.carousel * {
  box-sizing: border-box;
}

.carousel__track {
  display: flex;
  margin: 0;
  padding: 0;
  position: relative;
}

.carousel__viewport {
  overflow: hidden;
}

.carousel__icon {
  width: 1.2em;
  height: 1.2em;
  fill: currentColor;
}

.carousel__prev,
.carousel__next {
  background-color: theme("colors.red.500");
  border-radius: 15px;
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 20px;
  padding: 0;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: 0;
  cursor: pointer;
  outline: none;
}

.carousel__next:focus,
.carousel__prev:focus {
  outline: 0;
}

.carousel__prev {
  top: 50%;
  left: 0;
  transform: translate(20%, -50%);
}

.carousel__next {
  top: 50%;
  right: 0;
  transform: translate(-20%, -50%);
}

@media (min-width: 768px) {
  .carousel__prev,
  .carousel__next {
    border-radius: 20px;
    width: 40px;
    height: 40px;
  }

  .carousel__prev {
    transform: translate(30%, -50%);
  }

  .carousel__next {
    transform: translate(-30%, -50%);
  }
}

@media (min-width: 1024px) {
  .carousel__prev,
  .carousel__next {
    border-radius: 30px;
    width: 60px;
    height: 60px;
  }

  .carousel__prev {
    transform: translate(50%, -50%);
  }

  .carousel__next {
    transform: translate(-50%, -50%);
  }
}

.carousel__pagination {
  display: flex;
  justify-content: center;
  list-style: none;
}

.carousel__pagination-button {
  margin: 5px;
  width: 10px;
  height: 5px;
  border: 0;
  cursor: pointer;
  background-color: theme("colors.red.500");
}

.carousel__pagination-button--active {
  background-color: theme("colors.red.600");
}

.carousel__slide {
  scroll-snap-stop: auto;
  flex-shrink: 0;
  margin: 0;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
