<template>
  <section class="w-5/6 lg:w-2/3 px-4 md:px-10 document">
    <div v-html="cmsBlock.decoded_content.body"></div>
  </section>
</template>

<script>
export default {
  name: "TextBlock",
  props: {
    cmsBlock: {
      type: Object,
      required: true
    }
  }
};
</script>

<style>
.document h1 {
  @apply mt-4 mb-2 text-4xl font-medium leading-tight;
}

.document blockquote {
  @apply ml-8 border-l-4 border-gray-300 pl-8 py-8;
}

.document a {
  @apply font-medium;
}

.document em {
  @apply opacity-50;
}

.document ul {
  @apply ml-8 list-disc;
}

.document p {
  @apply my-2;
}
.document hr {
  @apply my-4;
}
</style>
