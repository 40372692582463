<template>
  <div class="relative pb-20 lg:pt-20">
    <div class="text-center">
      <h1
        class="mb-10 text-2xl px-4 md:px-0"
        v-html="cmsBlock.decoded_content.title"
      ></h1>
    </div>
    <div class="circle-frame-holder mx-auto">
      <div class="circle-frame">
        <div class="rotating-circle-holder">
          <img
            src="../../assets/img/circle_of_life.png"
            alt="circle of life"
            class="circle-of-life"
            style="aspect-ratio: 1/1"
          />
        </div>
      </div>
    </div>
    <div class="text-center mt-16 md:mt-20">
      <h2
        class="mb-10 text-2xl px-4 md:px-0"
        v-html="cmsBlock.decoded_content.question"
      ></h2>
      <router-link
        :to="{ name: 'Programme' }"
        class="mx-auto inline-block"
        ><red-dot :title="cmsBlock.decoded_content.question"
      /></router-link>
    </div>
    <svg
      width="248"
      height="480"
      viewBox="0 0 248 480"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="absolute z-10 left-mountain"
    >
      <path
        opacity="0.03"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1 0.80249L248 493.802H-246L1 0.80249Z"
        fill="#AE6B00"
      />
    </svg>
    <svg
      width="318"
      height="296"
      viewBox="0 0 318 296"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="absolute z-10 middle-mountain"
    >
      <path
        opacity="0.03"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M159 0.802551L318 318.803H0L159 0.802551Z"
        fill="#AE6B00"
      />
    </svg>
    <svg
      width="249"
      height="448"
      viewBox="0 0 249 448"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="absolute z-10 right-mountain"
    >
      <path
        opacity="0.03"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M246.753 0L493.432 493H0.0742188L246.753 0Z"
        fill="#AE6B00"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "CircleOfLife",
  props: {
    cmsBlock: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
.circle-frame-holder {
  width: 90%;
}

.circle-frame {
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 84.848485%;
  background-image: url("../../assets/img/circle_of_life_frame.jpg");
  background-repeat: no-repeat;
  background-size: contain;
}

.rotating-circle-holder {
  width: 50%;
  height: 0;
  padding-bottom: 100%;
  position: absolute;
  left: 25%;
  top: 21%;
}

.circle-of-life {
  animation: rotation 10s infinite linear;
}

.left-mountain {
  left: 0;
  bottom: -5px;
  width: 100px;
  height: 200px;
}

.middle-mountain {
  left: 20px;
  bottom: -10px;
  width: 120px;
  height: 120px;
}

.right-mountain {
  right: 0;
  bottom: -20px;
  width: 100px;
  height: 200px;
}

@media (min-width: 768px) {
  .left-mountain {
    left: 0;
    bottom: -5px;
    width: 200px;
    height: 400px;
  }

  .middle-mountain {
    left: 20px;
    bottom: -10px;
    width: 240px;
    height: 240px;
  }

  .right-mountain {
    right: 0;
    bottom: -20px;
    width: 200px;
    height: 400px;
  }

  .circle-frame-holder {
    width: 66%;
  }
}

@media (min-width: 1024px) {
  .circle-frame-holder {
    width: 42%;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}
</style>
