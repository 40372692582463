import store from "@/store/index";
import ApiService from "@/services/ApiService";

export default function auth({ next, router, to }) {
  //we're making a request to a route that should throw a 401 if we're not logged in
  ApiService.isLoggedIn()
    .then(() => {
      store.commit("user/setLoggedIn");

      return next();
    })
    .catch((error) => {
      if (error.response.status === 401) {
        store.commit("user/setPostLoginDestination", to);
        store.commit("user/setLoggedOut");
        router.push({ name: "Login" });
      }
    });
}
