import store from "@/store/index";
import ApiService from "@/services/ApiService";

export default function guest({ next, router }) {
  //we're making a request to a route that should throw a 401 if we're not logged in
  ApiService.isLoggedIn()
    .then(() => {
      //they're already logged in and session is still alive
      store.commit("user/setLoggedIn");
      router.push({
        name: "Course",
        params: {
          week: store.getters["course/getCurrentWeekNumber"]
        }
      });
    })
    .catch(() => {
      //they're not logged in
      store.commit("user/setLoggedOut");
      return next();
    });
}
