<template>
  <div class="w-5/6 mx-auto">
    <div class="w-full flex flex-row flex-wrap justify-between items-start">
      <three-col-image-text-col
        :column="cmsBlock.decoded_content.columns[0]"
        :images="cmsBlock.images"
        :index="1"
      />
      <three-col-image-text-col
        :column="cmsBlock.decoded_content.columns[1]"
        :images="cmsBlock.images"
        :index="2"
      />
      <three-col-image-text-col
        :column="cmsBlock.decoded_content.columns[2]"
        :images="cmsBlock.images"
        :index="3"
      />
    </div>
  </div>
</template>

<script>
import ThreeColImageTextCol from "@/components/blocks/ThreeColImageTextCol";

export default {
  name: "ThreeColImageText",
  components: {
    ThreeColImageTextCol
  },
  props: {
    cmsBlock: {
      type: Object,
      required: true
    }
  }
};
</script>
