import ApiService from "../../services/ApiService";

export default {
  namespaced: true,
  state: {
    questions: [],
    answers: [],
    email: null
  },
  getters: {
    getAnswerForQuestionId: (state) => (questionId) => {
      return state.answers.find((answer) => parseInt(answer.questionId) === parseInt(questionId));
    }
  },
  mutations: {
    setAnswer(state, payload) {
      if (state.answers.length === 0) {
        state.answers.push(payload);
      } else {
        let answerIdx = state.answers.findIndex((a) => {
          return a.questionId === payload.questionId;
        });

        if (answerIdx !== -1) {
          state.answers[answerIdx] = payload;
        } else {
          state.answers.push(payload);
        }
      }
    },
    reset(state) {
      state.email = null;
      state.answers = [];
      state.questions = [];
    },
    setEmail(state, payload) {
      state.email = payload.email;
    },
    setQuestions(state, payload) {
      state.questions = payload;
    }
  },
  actions: {
    fetchQuestions(context) {
      return new Promise((resolve, reject) => {
        ApiService.getSurveyQuestions()
          .then((response) => {
            context.commit("setQuestions", response.data.questions);
            resolve();
          })
          .catch((error) => {
            context.commit("setQuestions", []);
            reject(error);
          });
      });
    }
  }
};
