<template>
  <div class="flex flex-col justify-center items-center">
    <svg
      class="circle-of-life w-64 h-64"
      viewBox="0 0 1000 1000"
      id="svg"
    >
      <filter id="discBlur">
        <feGaussianBlur
          in="SourceGraphic"
          stdDeviation="30"
        />
      </filter>
      <mask id="mainMask">
        <rect
          x="0"
          y="0"
          width="1000"
          height="1000"
          fill="#000"
        />
        <circle
          cx="500"
          cy="500"
          r="500"
          stroke-width="0"
          fill="#fff"
        />
        <circle
          cx="500"
          cy="500"
          r="113"
          stroke="#fff"
          stroke-width="0"
          fill="#000"
          fill-opacity="1"
        />
      </mask>

      <g mask="url(#mainMask)">
        <circle
          cx="500"
          cy="500"
          r="500"
          stroke-width="0"
          fill="#fc3e4b"
        />

        <circle
          cx="500"
          cy="194"
          r="204"
          stroke-width="0"
          fill="#ec2e3b"
          transform="rotate(323, 500, 500)"
          filter="url(#discBlur)"
        />
        <circle
          cx="500"
          cy="194"
          r="194"
          stroke="#fff"
          stroke-width="0"
          stroke-opacity="0.9"
          fill="#fc3e4b"
          fill-opacity="1"
          transform="rotate(315, 500, 500)"
        />
        <rect
          x="250"
          y="0"
          width="250"
          height="500"
          fill="#fc3e4b"
          transform="rotate(315, 500, 500)"
        />

        <circle
          cx="500"
          cy="194"
          r="204"
          stroke-width="0"
          fill="#ec2e3b"
          transform="rotate(143, 500, 500)"
          filter="url(#discBlur)"
        />
        <circle
          cx="500"
          cy="194"
          r="194"
          stroke-width="0"
          fill="#fc3e4b"
          transform="rotate(135, 500, 500)"
        />
        <rect
          x="250"
          y="0"
          width="250"
          height="500"
          fill="#fc3e4b"
          transform="rotate(135, 500, 500)"
        />
      </g>
    </svg>
    <p class="text-center text-2xl font-bold mt-4">{{ message }}</p>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  props: {
    message: {
      type: String,
      default: "Loading..."
    }
  },
  data() {
    return {};
  }
};
</script>

<style scoped>
.circle-of-life {
  display: inline-block;
  margin: 0 auto;
  animation: rotation 10s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}
</style>
