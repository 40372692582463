import ApiService from "../../services/ApiService";

export default {
  namespaced: true,
  state: {
    loggedIn: false,
    tutorialWatched: null,
    courseStart: null,
    introVideoWatched: null,
    postLoginDestination: null,
    audioMuted: false,
    audioPlaybackSpeed: 1,
    audioTextVisibility: true
  },
  mutations: {
    setLoggedIn(state) {
      state.loggedIn = true;
    },
    setLoggedOut(state) {
      state.loggedIn = false;
    },
    setTutorialStatus(state, flag) {
      state.tutorialWatched = flag;
    },
    setCourseStart(state, date) {
      state.courseStart = date;
    },
    setIntroVideoStatus(state, flag) {
      state.introVideoWatched = flag;
    },
    reset(state) {
      state.loggedIn = false;
      state.tutorialWatched = null;
    },
    setPostLoginDestination(state, destination) {
      state.postLoginDestination = destination;
    },
    setAudioMuted(state, audioMuted) {
      state.audioMuted = audioMuted;
    },
    setAudioPlaybackSpeed(state, audioPlaybackSpeed) {
      state.audioPlaybackSpeed = audioPlaybackSpeed;
    },
    setAudioTextVisibility(state, audioTextVisibility) {
      state.audioTextVisibility = audioTextVisibility;
    }
  },
  actions: {
    logout(context) {
      return new Promise((resolve, reject) => {
        ApiService.getCsrf()
          .then(() => {
            ApiService.logout()
              .then(() => {
                context
                  .dispatch("clearAll", {}, { root: true })
                  .then(() => {
                    context.commit("setLoggedOut");
                    resolve();
                  })
                  .catch((error) => {
                    reject(error);
                  });
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    tutorialWatched(context) {
      return new Promise((resolve, reject) => {
        ApiService.getCsrf()
          .then(() => {
            ApiService.tutorialWatched()
              .then(() => {
                context.commit("setTutorialStatus", true);
                resolve();
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }
};
