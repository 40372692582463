import ApiService from "../../services/ApiService";

function addOptionIdToSelections(state, vector, optionId) {
  if (optionId === null) {
    return;
  }

  state.filterOptions.selections[vector].push(optionId);
}

function removeOptionIdFromSelections(state, vector, optionId) {
  if (optionId === null) {
    return;
  }

  let index = state.filterOptions.selections[vector].findIndex((s) => {
    return s === optionId;
  });

  if (index !== -1) {
    state.filterOptions.selections[vector].splice(index, 1);
  }
}

export default {
  namespaced: true,
  state: {
    content: [],
    filterOptions: {
      open: {
        sections: false,
        feelings: false,
        formats: false
      },
      sections: [{ id: null, title: "Anything", active: true }],
      feelings: [{ id: null, title: "Everything", active: true }],
      formats: [{ id: null, title: "Any type of content", active: true }],
      // putting the state here as well for filtering performance, and also to allow for the potential separation
      // of choosing options and applying the filter
      selections: {
        sections: [],
        feelings: [],
        formats: []
      }
    }
  },
  getters: {
    getContentById: (state) => (contentId) => {
      let index = state.content.findIndex((c) => {
        return parseInt(c.id, 10) === parseInt(contentId, 10);
      });

      if (index === -1) {
        return null;
      }

      return state.content[index];
    },
    getVectorDefaultOption: (state) => (vector) => {
      let index = state.filterOptions[vector].findIndex((o) => {
        return o.id === null;
      });

      return state.filterOptions[vector][index].title;
    }
  },
  mutations: {
    reset(state) {
      state.content = [];
      state.filterOptions.open.sections = false;
      state.filterOptions.open.feelings = false;
      state.filterOptions.open.formats = false;
      state.filterOptions.selections.sections = [];
      state.filterOptions.selections.feelings = [];
      state.filterOptions.selections.formats = [];
    },
    setContent(state, content) {
      state.content = content;
    },
    favouriteContentById(state, payload) {
      let index = state.content.findIndex((c) => {
        return parseInt(c.id, 10) === parseInt(payload.id, 10);
      });

      if (index !== -1) {
        state.content[index].favourites.push(payload.favourite);
      }
    },
    disfavourContentById(state, payload) {
      let index = state.content.findIndex((c) => {
        return parseInt(c.id, 10) === parseInt(payload.id, 10);
      });

      if (index !== -1) {
        state.content[index].favourites = [];
      }
    },
    addContentItem(state, contentItem) {
      let index = state.content.findIndex((c) => {
        return parseInt(c.id, 10) === parseInt(contentItem.id, 10);
      });

      if (index === -1) {
        state.content.push(contentItem);
      } else {
        state.content[index] = contentItem;
      }
    },
    toggleFilterOptionsOpenForVector(state, vector) {
      state.filterOptions.open[vector] = !state.filterOptions.open[vector];
      //if we've just opened one make sure the others close
      if (state.filterOptions.open[vector]) {
        let v;
        for (v in state.filterOptions.open) {
          if (v !== vector) {
            state.filterOptions.open[v] = false;
          }
        }
      }
    },
    toggleFilterOption(state, payload) {
      state.filterOptions[payload.vector].forEach((o) => {
        if (o.id === payload.option.id) {
          o.active = !o.active;
          if (o.active) {
            addOptionIdToSelections(state, payload.vector, o.id);
          } else {
            removeOptionIdFromSelections(state, payload.vector, o.id);
          }

          //if this is not the default, and it's been activated, make sure default is deactivated
          if (o.active && o.id) {
            state.filterOptions[payload.vector].forEach((od) => {
              if (!od.id) {
                od.active = false;
                removeOptionIdFromSelections(state, payload.vector, od.id);
              }
            });
          }
          //if this is not the default and it's been deactivated,
          //make sure default is set to active if there are no others set
          if (o.id && !o.active) {
            let otherSet = false;
            state.filterOptions[payload.vector].forEach((o2) => {
              if (o2.active) {
                otherSet = true;
              }
            });
            if (!otherSet) {
              state.filterOptions[payload.vector].forEach((od) => {
                if (!od.id) {
                  od.active = true;
                  addOptionIdToSelections(state, payload.vector, od.id);
                }
              });
            }
          }
        }
      });
    },
    clearFilterVector(state, vector) {
      state.filterOptions[vector].forEach((o) => {
        o.active = o.id === null;
        state.filterOptions.selections[vector] = [];
      });
    },
    addFilterOptions(state, filterOptions) {
      //make sure we don't add them more than once
      if (state.filterOptions.formats.length === 1) {
        filterOptions.formats.forEach((f) => {
          state.filterOptions.formats.push(f);
        });

        filterOptions.feelings.forEach((f) => {
          state.filterOptions.feelings.push(f);
        });

        filterOptions.sections.forEach((s) => {
          state.filterOptions.sections.push(s);
        });
      }
    }
  },
  actions: {
    fetchContent(context) {
      return new Promise((resolve, reject) => {
        ApiService.getLibraryContent()
          .then((response) => {
            context.commit("setContent", response.data.content);
            context.commit("addFilterOptions", response.data.filterOptions);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    primeContent(context) {
      return new Promise((resolve, reject) => {
        //checking for cover_image to make sure locale storage is updated after the move to imageable
        //checking for format id 6 to make sure locale storage is updated after the move to che content fake format
        if (
          context.state.content.length > 0 &&
          context.state.content[0].cover_image &&
          context.state.filterOptions.formats.length === 5
        ) {
          resolve();
        } else {
          context
            .dispatch("fetchContent")
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    }
  }
};
