<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 33.866664 33.866664"
    height="128"
    width="128"
    class="dot"
  >
    <circle
      r="15.4666662"
      cy="16.933332"
      cx="16.933332"
      class="dot-outer-rim"
    />
    <circle
      r="11.4666662"
      cy="16.933332"
      cx="16.933332"
      class="dot-rim"
    />
    <circle
      r="8.4666662"
      cy="16.933332"
      cx="16.933332"
      class="dot-body"
    />
    <circle
      r="8.1259604"
      cy="16.933332"
      cx="16.933332"
      class="dot-outline"
      ref="dotOutline"
    />
  </svg>
</template>

<script>
export default {
  name: "RedDot",
  data() {
    return {
      originalRadius: 1,
      outlinePercentage: 100,
      dotAnimationTimer: null,
      delay: 30
    };
  },
  beforeUnmount() {
    window.clearTimeout(this.dotAnimationTimer);
  },
  mounted() {
    this.$nextTick(() => {
      this.originalRadius = this.$refs.dotOutline.r.baseVal.value;
      this.dotAnimationTimer = window.setTimeout(() => {
        this.resizeDotOutline();
      }, this.delay);
    });
  },
  methods: {
    resizeDotOutline() {
      let delay = this.delay;
      let strokeOpacity = 1;
      if (this.outlinePercentage > 199) {
        this.outlinePercentage = 100;
        delay = 1000;
      } else {
        this.outlinePercentage += 2;
      }
      let newRadius = (this.originalRadius / 100) * this.outlinePercentage;
      strokeOpacity = 1 - 0.0095 * (this.outlinePercentage - 100);

      if (this.$refs.dotOutline) {
        this.$refs.dotOutline.setAttribute("r", newRadius);
        this.$refs.dotOutline.setAttribute("style", "stroke-opacity: " + strokeOpacity + ";");
        // this.$refs.dotOutline.setAttribute("opacity", 0);
      }

      this.dotAnimationTimer = window.setTimeout(() => {
        this.resizeDotOutline();
      }, delay);
    }
  }
};
</script>

<style scoped>
.dot-outer-rim {
  opacity: 1;
  fill: #fff;
  fill-opacity: 0;
  stroke: theme("colors.red.500");
  stroke-width: 0.2;
  stroke-miterlimit: 4;
  stroke-dasharray: none;
  stroke-opacity: 0.2;
}
.dot-rim {
  opacity: 1;
  fill: theme("colors.cream-default");
  fill-opacity: 0;
  stroke: theme("colors.red.500");
  stroke-width: 0.2;
  stroke-miterlimit: 4;
  stroke-dasharray: none;
  stroke-opacity: 1;
  transition: fill 600ms ease-in-out;
}
.dot-outline {
  fill-opacity: 0;
  fill: theme("colors.red.500");
  stroke: theme("colors.red.500");
  stroke-width: 0.68141174;
  stroke-miterlimit: 4;
  stroke-dasharray: none;
}

.dot-body {
  opacity: 1;
  fill: theme("colors.red.500");
  fill-opacity: 1;
  stroke: theme("colors.red.500");
  stroke-width: 0;
  stroke-miterlimit: 4;
  stroke-dasharray: none;
  stroke-opacity: 1;
  transition: fill 600ms ease-in-out;
}
/*.dot:hover .dot-body {*/
/*    fill: #fd656f;*/
/*}*/
.dot:hover .dot-body {
  fill: theme("colors.cream-default");
}
</style>
