import TwoTextColumnsImageBackground from "@/components/blocks/TwoTextColumnsImageBackground";
import CircleOfLife from "@/components/blocks/CircleOfLife";
import FullWidthImage from "@/components/blocks/FullWidthImage";
import ScreenshotImage from "@/components/blocks/ScreenshotImage";
import TextBlock from "@/components/blocks/TextBlock";
import Testimonials from "@/components/blocks/Testimonials";
import ThreeColImageText from "@/components/blocks/ThreeColImageText";
import ContactForm from "@/components/blocks/ContactForm";

import IsCheRightForYouNow from "@/components/blocks/IsCheRightForYouNow";
import AudioTrack from "@/components/blocks/AudioTrack";
import ReadMoreText from "@/components/blocks/ReadMoreText";
import Document from "@/components/blocks/Document";
import StartSurvey from "@/components/blocks/StartSurvey";

import Spinner from "@/components/utilities/Spinner";

export default {
  components: {
    TwoTextColumnsImageBackground,
    CircleOfLife,
    FullWidthImage,
    ScreenshotImage,
    TextBlock,
    Testimonials,
    ThreeColImageText,
    ContactForm,
    IsCheRightForYouNow,
    ReadMoreText,
    AudioTrack,
    Document,
    StartSurvey,
    Spinner
  },
  data() {
    return {
      loaded: false,
      cmsBlocks: []
    };
  },
  created() {
    this.fetchBlocks();
  }
};
