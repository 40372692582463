<script setup>
import { computed } from "vue";
import { useFindImageVariant, useFindImageByType } from "@/composables/imageHelpers.js";

const props = defineProps({
  column: {
    type: Object,
    required: true
  },
  images: {
    type: Object,
    required: true
  },
  index: {
    type: Number,
    required: true
  }
});

const webpSrcset = computed(() => {
  const imageType = "three_col_" + props.index;
  const image = useFindImageByType(props.images, imageType);
  const retina2x = useFindImageVariant(image.image_variants, "webp", "sm", 1);
  const normal = useFindImageVariant(image.image_variants, "webp", "sm", 0);

  if (retina2x) {
    return `${normal.url} 1x, ${retina2x.url} 2x`;
  }

  return normal.url;
});
</script>

<template>
  <div class="w-full md:w-1/3 px-10 text-center">
    <picture>
      <source
        :srcset="webpSrcset"
        type="image/webp"
        class="w-full object-cover"
      />
      <source
        :srcset="column.jpg_image_url"
        type="image/jpeg"
      />
      <img
        :src="column.jpg_image_url"
        alt=""
        loading="lazy"
        class="w-full object-cover"
      />
    </picture>
    <p
      class="text-center p-4"
      v-html="column.body"
    ></p>
  </div>
</template>

<style scoped>
picture {
  width: 100%;
  /* display: flex; */
}

/* picture img {
    object-fit: contain;
    height: auto;
    width: 100%;
} */
</style>
