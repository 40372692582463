/**
 *
 * @param {[]} imageVariants
 * @param {string} format
 * @param {string} sizeName
 * @param {boolean} isRetina2x
 */
export const useFindImageVariant = function (imageVariants, format, sizeName, isRetina2x) {
  return imageVariants.find((iv) => {
    return iv.format === format && iv.size_name === sizeName && iv.retina_2x == isRetina2x;
  });
};

/**
 *
 * @param {[]} images
 * @param {string} type
 * @returns
 */
export const useFindImageByType = function (images, type) {
  return images.find((i) => i.image_type.type === type);
};
