<template>
  <section class="w-5/6 lg:w-2/3 mx-auto">
    <h1
      v-if="cmsBlock.decoded_content.heading"
      v-html="cmsBlock.decoded_content.heading"
      class="text-lg lg:text-3xl font-bold mb-8"
    ></h1>
    <div
      v-html="cmsBlock.decoded_content.body"
      class="text-base leading-relaxed lg:text-2xl lg:leading-relaxed"
    ></div>
  </section>
</template>

<script>
export default {
  name: "TextBlock",
  props: {
    cmsBlock: {
      type: Object,
      required: true
    }
  }
};
</script>
