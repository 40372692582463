<template>
  <div
    ref="main-page"
    class="relative content-bg"
    :class="{ 'public-container': usePublicContainer, 'pb-20': showPublicHeader }"
  >
    <transition name="vertical-slide">
      <header
        v-if="showPublicHeader"
        class="px-8 lg:px-12 public-container content-bg"
      >
        <nav class="flex flex-row justify-between items-center content-bg">
          <div class="flex-auto">
            <router-link
              :to="{ name: 'Home' }"
              class="py-3 md:py-12 flex flex-row justify-start items-center"
            >
              <img
                src="./assets/img/che_logo.png"
                alt="CHE"
                class="logo-image"
                width="50"
                height="50"
              />
              <span class="logo-text pl-2">CHE</span></router-link
            >
          </div>
          <div class="hidden md:block">
            <router-link
              :to="{ name: 'RequestAccess' }"
              v-if="$route.name === 'Login'"
              >Request Access
            </router-link>
            <!-- <router-link
                            v-else-if="!$store.state.user.loggedIn"
                            :to="{ name: 'Programme' }"
                            class="inline-block  py-8 px-8 lg:py-12 lg:px-12 primary-link flex-1"
                        >
                            Would you like to live your most valuable life?
                        </router-link> -->
            <router-link
              v-else-if="$store.state.user.loggedIn && tutorialWatched"
              :to="{
                name: 'Course',
                params: {
                  week: $store.getters['course/getCurrentWeekNumber']
                }
              }"
              class="inline-block py-8 px-8 lg:py-12 lg:px-12 primary-link flex-1"
            >
              Course
            </router-link>
            <router-link
              v-else-if="$store.state.user.loggedIn && !tutorialWatched"
              :to="{ name: 'Tutorial' }"
              class="inline-block py-8 px-8 lg:py-12 lg:px-12 primary-link flex-1"
            >
              Tutorial
            </router-link>
            <router-link
              v-if="!$store.state.user.loggedIn && $route.name === 'Home'"
              :to="{ name: 'Login' }"
              class="btn btn-primary btn-header"
            >
              Login
            </router-link>
            <button
              v-if="$store.state.user.loggedIn"
              @click="logout"
              class="btn btn-primary btn-header"
            >
              Logout
            </button>
          </div>
          <div class="md:hidden">
            <span @click="toggleMobileMenu()"
              ><img
                src="./assets/img/icons/icon-menu.svg"
                alt="menu"
                width="24"
                height="16"
            /></span>
          </div>
        </nav>
      </header>
    </transition>
    <router-view
      v-slot="{ Component }"
      :key="$route.fullPath"
    >
      <transition
        :name="transitionName"
        mode="out-in"
      >
        <component :is="Component" />
      </transition>
    </router-view>
    <footer
      v-if="showPublicHeader && !hideFooter"
      class="text-center text-vs absolute bottom-0 left-0 right-0 opacity-50 mb-2"
    >
      &copy; Creative Human Evolution Ltd. 1-2 Charterhouse Mews, Barbican, London, EC1M 6BB, United Kingdom |
      <router-link :to="{ name: 'PrivacyPolicy' }">Privacy Policy</router-link> |
      <router-link :to="{ name: 'TermsAndConditions' }">Terms and Conditions</router-link>
    </footer>
  </div>

  <transition name="fade">
    <aside
      v-if="updateExists"
      class="fixed bottom-0 right-0 mr-10 mb-10 w-64 h-32 bg-white shadow-lg rounded-md flex flex-col justify-center items-center flex-wrap border border-gray-400"
    >
      <h1 class="mb-2">New content is available</h1>
      <button
        class="btn btn-primary btn-sm"
        @click="refreshApp"
      >
        Please Refresh
      </button>
    </aside>
  </transition>

  <transition name="fade">
    <div
      ref="mobile-menu"
      class="flex md:hidden absolute inset-0 content-bg z-20 flex-col w-full justify-end"
      v-if="mobileMenuActive"
    >
      <span
        class="absolute top-0 right-0 mr-4 mt-4"
        @click="toggleMobileMenu()"
        ><img
          src="./assets/img/icons/icon-close.svg"
          alt="close"
        />
      </span>
      <div class="flex-1 flex flex-col justify-end">
        <div
          v-if="!$store.state.user.loggedIn"
          class="primary-link flex-none text-center"
        >
          <red-dot
            @click="goTo('Programme')"
            class="w-32 mx-auto"
          ></red-dot>
          <p class="text-center w-1/2 mx-auto">Would you like to live your most valuable life?</p>
        </div>
        <button
          v-else-if="tutorialWatched"
          @click="
            goTo('Course', {
              week: $store.getters['course/getCurrentWeekNumber']
            })
          "
          class="inline-block py-8 px-8 lg:py-12 lg:px-12 primary-link flex-1"
        >
          course
        </button>
        <button
          v-else
          @click="goTo('Tutorial')"
          class="inline-block py-8 px-8 lg:py-12 lg:px-12 primary-link flex-1"
        >
          Tutorial
        </button>
      </div>
      <div class="flex-1 flex flex-col justify-center pb-8 px-8">
        <button
          @click="goTo('Login')"
          v-if="!$store.state.user.loggedIn"
          class="btn btn-primary btn-header"
        >
          Login
        </button>
        <button
          v-else
          @click="logout"
          class="btn btn-primary btn-header"
        >
          Logout
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import ApiService from "@/services/ApiService";
import update from "@/mixins/update";

const DEFAULT_TRANSITION = "fade";

export default {
  name: "CHE",
  mixins: [update],
  data() {
    return {
      transitionName: DEFAULT_TRANSITION,
      mobileMenuActive: false
    };
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName;

      if (to.name === "Library" && from.name === "LibraryContent") {
        transitionName = "fade";
      }

      this.transitionName = transitionName || DEFAULT_TRANSITION;

      next();
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  },
  mounted() {
    if (this.$store.state.user.loggedIn) {
      // check to see if they've got the same siteVersion as the webserver
      // if they haven't then flush their vuex (and localstorage)
      // any pages that rely on vuex data should make their own check to ensure their data is primed
      ApiService.getSiteVersion()
        .then((response) => {
          if (response.data.siteVersion !== this.$store.state.site.siteVersion) {
            window.localStorage.clear();
            this.$store.dispatch("clearAll").then(() => {
              this.$store.commit("user/setLoggedIn");
              this.$store.commit("site/setSiteVersion", response.data.siteVersion);
            });
          }
        })
        .catch(() => {
          window.localStorage.clear();
          this.$store.dispatch("clearAll").catch(() => {});
        });
    }
    window.addEventListener("resize", this.handleWindowResize);
    this.handleWindowResize();
  },
  computed: {
    tutorialWatched() {
      return this.$store.state.user.tutorialWatched !== false;
    },
    showPublicHeader() {
      return this.$route.meta.publicHeader;
    },
    hideFooter() {
      return (
        ["Survey", "SurveyQuestion", "SurveyComplete", "SurveyEmail"].findIndex((rn) => rn === this.$route.name) !== -1
      );
    },
    usePublicContainer() {
      return this.showPublicHeader;
    }
  },
  methods: {
    handleWindowResize() {
      this.$refs["main-page"].style.minHeight = window.innerHeight + "px";
      if (this.mobileMenuActive) {
        this.$refs["mobile-menu"].style.minHeight = window.innerHeight + "px";
      }
    },
    toggleMobileMenu() {
      let body = document.getElementById("body");
      if (this.mobileMenuActive) {
        window.setTimeout(() => {
          body.classList.toggle("mobile-menu-active");
        }, 400);
      } else {
        body.classList.toggle("mobile-menu-active");
      }

      this.mobileMenuActive = !this.mobileMenuActive;
    },
    logout() {
      this.$router.push({ name: "Logout" });
    },
    goTo(routeName, params = {}) {
      this.toggleMobileMenu();
      this.$router.push({ name: routeName, params });
    }
  }
};
</script>

<style>
@import "assets/styles/tailwind.postcss";

html,
body {
  @apply bg-cream-lighter;
  font-family: "Museo Slab", verdana, arial, sans-serif;
}

body.mobile-menu-active {
  overflow: hidden;
}

.content-bg {
  @apply bg-cream-default;
}

.primary-link,
.secondary-link {
  font-weight: 500;
}

.primary-link {
  opacity: 1;
}

.secondary-link {
  opacity: 0.5;
}

.logo-image {
  width: 30px;
  height: 30px;
  animation: rotation 10s infinite linear;
}

.logo-text {
  font-size: 14px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .logo-image {
    width: 50px;
    height: 50px;
  }

  .logo-text {
    font-size: 24px;
    font-weight: 500;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}

.public-container {
  max-width: 1440px;
  margin: 0 auto;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.fade-enter-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-leave-active {
  transition: all 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0.5;
}

.delayed-fade-enter-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1) 0.5s;
}

.delayed-fade-leave-active {
  transition: all 0.2s ease;
}

.delayed-fade-enter-from,
.delayed-fade-leave-to {
  opacity: 0;
}

.vertical-slide-enter-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.vertical-slide-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.vertical-slide-enter-from,
.vertical-slide-leave-to {
  transform: translateY(-100%);
}

input::placeholder,
textarea::placeholder {
  font-weight: 500;
  color: black;
  opacity: 0.3;
}

input:focus,
textarea:focus {
  outline: 1px rgba(0, 0, 0, 0.6);
}
</style>
