import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import Home from "../views/Home.vue";
import auth from "../middleware/auth.js";
import guest from "../middleware/guest.js";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      middleware: guest,
      publicHeader: true
    }
  },
  {
    path: "/would-you-like-to-live-your-most-valuable-life",
    name: "Programme",
    component: () => import(/* webpackChunkName: "programme" */ "../views/Programme.vue"),
    meta: {
      middleware: guest,
      publicHeader: true
    }
  },
  {
    path: "/is-che-right-for-you-now-introduction",
    name: "SurveyIntroduction",
    component: () => import(/* webpackChunkName: "programme" */ "../views/SurveyIntroduction.vue"),
    meta: {
      middleware: guest,
      publicHeader: true
    }
  },
  {
    path: "/is-che-right-for-you-now",
    name: "Survey",
    component: () => import(/* webpackChunkName: "survey" */ "../views/Survey.vue"),
    meta: {
      middleware: guest,
      publicHeader: true
    },
    children: [
      {
        path: "question/:questionIndex",
        name: "SurveyQuestion",
        component: () => import(/* webpackChunkName: "surveyQuestion" */ "../views/survey/Question.vue"),
        meta: {
          publicHeader: true
        }
      },
      {
        path: "complete",
        name: "SurveyComplete",
        component: () => import(/* webpackChunkName: "surveyComplete" */ "../views/survey/Complete.vue"),
        meta: {
          publicHeader: true
        }
      },
      {
        path: "email",
        name: "SurveyEmail",
        component: () => import(/* webpackChunkName: "surveyEmail" */ "../views/survey/Email.vue"),
        meta: {
          publicHeader: true
        }
      }
    ]
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import(/* webpackChunkName: "privacyPolicy" */ "../views/PrivacyPolicy.vue"),
    meta: {
      middleware: guest,
      publicHeader: true
    }
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    component: () => import(/* webpackChunkName: "termsAndConditions" */ "../views/TermsAndConditions.vue"),
    meta: {
      middleware: guest,
      publicHeader: true
    }
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: {
      middleware: guest,
      publicHeader: true
    }
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import(/* webpackChunkName: "logout" */ "../views/Logout.vue"),
    meta: {
      publicHeader: true
    }
  },
  {
    path: "/forgotten-password",
    name: "ForgottenPassword",
    component: () => import(/* webpackChunkName: "forgottenPassword" */ "../views/ForgottenPassword.vue"),
    meta: {
      publicHeader: true
    }
  },
  {
    path: "/password/reset",
    name: "PasswordReset",
    component: () => import(/* webpackChunkName: "passwordReset" */ "../views/PasswordReset.vue"),
    meta: {
      publicHeader: true
    }
  },
  {
    path: "/request-access",
    name: "RequestAccess",
    component: () => import(/* webpackChunkName: "requestAccess" */ "../views/RequestAccess.vue"),
    meta: {
      publicHeader: true
    }
  },

  {
    path: "/network-issues",
    name: "NetworkIssues",
    component: () => import(/* webpackChunkName: "networkIssues" */ "../views/NetworkIssues.vue"),
    meta: {
      publicHeader: true
    }
  },
  {
    path: "/503",
    name: "Maintenance",
    component: () => import(/* webpackChunkName: "maintenance" */ "../views/Maintenance.vue"),
    meta: {
      publicHeader: true
    }
  },
  {
    path: "/404",
    name: "Error404",
    component: () => import(/* webpackChunkName: "error404" */ "../views/Error404.vue"),
    meta: {
      publicHeader: true
    }
  },

  /**
   * *****************************************************************
   * *****************************************************************
   * *****************************************************************
   * ALL THE FOLLOWING ROUTES REQUIRE AUTH
   * *****************************************************************
   * *****************************************************************
   * *****************************************************************
   */
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: {
      middleware: auth,
      transitionName: "none"
    },
    component: () => import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue")
  },
  {
    path: "/library/:id",
    name: "LibraryContent",
    meta: {
      middleware: auth,
      transitionName: "fade"
    },
    component: () => import(/* webpackChunkName: "libraryContent" */ "../views/LibraryContent.vue")
  },
  {
    path: "/library",
    name: "Library",
    meta: {
      middleware: auth,
      transitionName: "none"
    },
    component: () => import(/* webpackChunkName: "library" */ "../views/Library.vue")
  },
  {
    path: "/chapters",
    name: "Chapters",
    meta: {
      middleware: auth,
      transitionName: "none"
    },
    async beforeEnter(to, from, next) {
      await store.dispatch("course/primeCurrentWeek");
      next();
    },
    component: () => import(/* webpackChunkName: "chapters" */ "../views/Chapters.vue")
  },
  {
    path: "/account",
    name: "Account",
    meta: {
      middleware: auth,
      transitionName: "none"
    },
    component: () => import(/* webpackChunkName: "account" */ "../views/Account.vue"),
    children: [
      {
        path: "",
        name: "ValueSignature",
        component: () => import(/* webpackChunkName: "valueSignature" */ "../views/account/Profile.vue"),
        meta: {
          middleware: auth,
          publicHeader: false
        }
      },
      {
        path: "bookmarks",
        name: "Bookmarks",
        component: () => import(/* webpackChunkName: "favourites" */ "../views/account/Bookmarks.vue"),
        meta: {
          middleware: auth,
          publicHeader: false
        }
      },
      {
        path: "announcements",
        name: "Announcements",
        component: () => import(/* webpackChunkName: "announcements" */ "../views/account/Announcements.vue"),
        meta: {
          middleware: auth,
          publicHeader: false
        }
      },
      {
        path: "profile",
        name: "Profile",
        component: () => import(/* webpackChunkName: "profile" */ "../views/account/Profile.vue"),
        meta: {
          middleware: auth,
          publicHeader: false
        }
      },
      {
        path: "settings",
        name: "Settings",
        component: () => import(/* webpackChunkName: "settings" */ "../views/account/Settings.vue"),
        meta: {
          middleware: auth,
          publicHeader: false
        }
      },
      {
        path: "",
        name: "Achievements",
        component: () => import(/* webpackChunkName: "achievements" */ "../views/progress/Achievements.vue"),
        meta: {
          middleware: auth,
          publicHeader: false
        }
      },
      {
        path: "daily-checks",
        name: "DailyChecks",
        component: () => import(/* webpackChunkName: "dailyChecks" */ "../views/progress/DailyChecks.vue"),
        meta: {
          middleware: auth,
          publicHeader: false
        }
      },
      {
        path: "notes",
        name: "Notes",
        component: () => import(/* webpackChunkName: "notes" */ "../views/progress/Notes.vue"),
        meta: {
          middleware: auth,
          publicHeader: false
        }
      }
    ]
  },
  {
    path: "/course/:week/:day/reflections",
    name: "CourseContentReflections",
    component: () => import(/* webpackChunkName: "CourseContentReflections" */ "../views/CourseContentReflections.vue"),
    meta: {
      middleware: auth,
      publicHeader: false
    }
  },
  {
    path: "/course/:week/:day/related-content",
    name: "CourseRelatedContent",
    component: () => import(/* webpackChunkName: "CourseRelatedContent" */ "../views/CourseRelatedContent.vue"),
    meta: {
      middleware: auth,
      publicHeader: false
    }
  },
  {
    path: "/course/:week/:day/complete",
    name: "CourseContentComplete",
    component: () => import(/* webpackChunkName: "CourseContentComplete" */ "../views/CourseContentComplete.vue"),
    meta: {
      middleware: auth,
      publicHeader: false
    }
  },
  {
    path: "/course/:week/:day",
    name: "CourseContent",
    component: () => import(/* webpackChunkName: "courseContent" */ "../views/CourseContent.vue"),
    meta: {
      middleware: auth,
      publicHeader: false
    }
  },
  {
    path: "/course/:week",
    name: "Course",
    component: () => import(/* webpackChunkName: "course" */ "../views/Course.vue"),
    meta: {
      middleware: auth,
      transitionName: "none",
      publicHeader: false
    }
  },
  {
    path: "/tutorial",
    name: "Tutorial",
    component: () => import(/* webpackChunkName: "tutorial" */ "../views/Tutorial.vue"),
    meta: {
      middleware: auth,
      publicHeader: false
    }
  },
  {
    path: "/tutorial/2",
    name: "TutorialStep2",
    component: () => import(/* webpackChunkName: "tutorialStep2" */ "../views/TutorialStep2.vue"),
    meta: {
      middleware: auth,
      publicHeader: false
    }
  },
  {
    path: "/tutorial/3",
    name: "TutorialStep3",
    component: () => import(/* webpackChunkName: "tutorialStep3" */ "../views/TutorialStep3.vue"),
    meta: {
      middleware: auth,
      publicHeader: false
    }
  }
];

const router = createRouter({
  history: createWebHistory("/"),
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
  routes
});

router.beforeResolve(function (to, from, next) {
  to.matched.length ? next() : next({ name: "Error404" });
});

//see: https://markus.oberlehner.net/blog/implementing-a-simple-middleware-with-vue-router/
// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    /*
        whilst we're only using an auth middleware this isn't a problem,
        if we add any other type of middleware we may need to move this to after the metadata and priming code
         */
    return middleware[0]({ ...context, next: nextMiddleware });
  }

  try {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched
      .slice()
      .reverse()
      .find((r) => r.meta && r.meta.metaTags);
    // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map((el) => el.parentNode.removeChild(el));

    // Skip rendering meta tags if there are none.
    if (nearestWithMeta) {
      // Turn the meta tag definitions into actual elements in the head.
      nearestWithMeta.meta.metaTags
        .map((tagDef) => {
          const tag = document.createElement("meta");

          Object.keys(tagDef).forEach((key) => {
            tag.setAttribute(key, tagDef[key]);
          });

          // We use this to track which meta tags we create, so we don't interfere with other ones.
          tag.setAttribute("data-vue-router-controlled", "");

          return tag;
        })
        .forEach((tag) => document.head.appendChild(tag));
    }
  } catch (err) {
    //console.log(err);
  }

  next();
});

export default router;
