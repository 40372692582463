class SecondsToTime {
  /**
   * Create a new Errors instance.
   */
  constructor(seconds) {
    this.seconds = seconds;
  }

  getTime() {
    let hours, minutes, seconds;

    hours = Math.floor(this.seconds / 60 / 60);

    minutes = Math.floor(this.seconds / 60) - hours * 60;

    seconds = Math.round(this.seconds % 60);

    return (
      hours.toString().padStart(2, "0") +
      ":" +
      minutes.toString().padStart(2, "0") +
      ":" +
      seconds.toString().padStart(2, "0")
    );
  }
}

export default SecondsToTime;
