<script setup>
import { computed } from "vue";
import { useFindImageVariant, useFindImageByType } from "@/composables/imageHelpers.js";

const props = defineProps({
  cmsBlock: {
    type: Object,
    required: true
  }
});

const alt = computed(() => {
  return props.cmsBlock.images[0].alt ? props.cmsBlock.images[0].alt : "";
});

const hasImages = computed(() => {
  return props.cmsBlock.images && props.cmsBlock.images.length > 0;
});

const webpDesktopSrcset = computed(() => {
  const image = useFindImageByType(props.cmsBlock.images, "screenshot_desktop");
  const retina2x = useFindImageVariant(image.image_variants, "webp", "lg", 1);
  const normal = useFindImageVariant(image.image_variants, "webp", "lg", 0);

  if (retina2x) {
    return `${normal.url} 1x, ${retina2x.url} 2x`;
  }

  return normal.url;
});

const jpgDesktopSrcset = computed(() => {
  const image = useFindImageByType(props.cmsBlock.images, "screenshot_desktop");
  const retina2x = useFindImageVariant(image.image_variants, "jpg", "lg", 1);
  const normal = useFindImageVariant(image.image_variants, "jpg", "lg", 0);

  if (retina2x) {
    return `${normal.url} 1x, ${retina2x.url} 2x`;
  }

  return normal.url;
});

const webpMobileSrcset = computed(() => {
  let image = useFindImageByType(props.cmsBlock.images, "screenshot_mobile");
  if (!image) {
    //use the desktop image at smaller size
    image = useFindImageByType(props.cmsBlock.images, "screenshot_desktop");
  }
  const retina2x = useFindImageVariant(image.image_variants, "webp", "sm", 1);
  const normal = useFindImageVariant(image.image_variants, "webp", "sm", 0);

  if (retina2x) {
    return `${normal.url} 1x, ${retina2x.url} 2x`;
  }

  return normal.url;
});

const jpgMobileSrcset = computed(() => {
  let image = useFindImageByType(props.cmsBlock.images, "screenshot_mobile");
  if (!image) {
    //use the desktop image at smaller size
    image = useFindImageByType(props.cmsBlock.images, "screenshot_desktop");
  }
  const retina2x = useFindImageVariant(image.image_variants, "jpg", "sm", 1);
  const normal = useFindImageVariant(image.image_variants, "jpg", "sm", 0);

  if (retina2x) {
    return `${normal.url} 1x, ${retina2x.url} 2x`;
  }

  return normal.url;
});

const jpgFallbackSrc = computed(() => {
  let normal = useFindImageVariant(props.cmsBlock.images[0].image_variants, "jpg", "lg", 0);

  return normal.url;
});
</script>
<template>
  <div class="w-5/6 mx-auto">
    <picture
      v-if="hasImages"
      class="w-full object-cover"
    >
      <source
        media="(max-width: 767px)"
        :srcset="webpMobileSrcset"
        type="image/webp"
      />
      <source
        media="(min-width: 768px)"
        :srcset="webpDesktopSrcset"
        type="image/webp"
      />
      <source
        media="(max-width: 767px)"
        :srcset="jpgMobileSrcset"
        type="image/jpeg"
      />
      <source
        media="(min-width: 768px)"
        :srcset="jpgDesktopSrcset"
        type="image/jpeg"
      />
      <img
        :src="jpgFallbackSrc"
        :alt="alt"
        loading="lazy"
        class="w-full object-cover"
      />
    </picture>
  </div>
</template>
