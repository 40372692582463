import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

// import VueMeta from "vue-meta"; //not yet compatible with vue 3

import RedDot from "@/components/buttons/RedDot";
// eslint-disable-next-line no-unused-vars
// import modernizr from "modernizr";

const che = createApp(App).use(store).use(router).component("red-dot", RedDot);

router.isReady().then(() => {
  che.mount("#che");
});
