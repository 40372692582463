import axios from "axios";
import router from "../router";

const apiClient = axios.create({
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json"
  },
  timeout: 15000
});

apiClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && 503 === error.response.status) {
      router.push({ name: "Maintenance" }).catch(() => {
        throw new Error("There was a problem redirecting to the Maintenance page.");
      });
    } else if (!error || !error.response) {
      router.push({ name: "NetworkIssues" }).catch(() => {
        throw new Error("There was a problem redirecting to the NetworkIssues page.");
      });
    }

    return Promise.reject(error);
  }
);

export default {
  getCsrf() {
    return apiClient.get("/sanctum/csrf-cookie");
  },

  //Survey
  getSurveyQuestions() {
    return apiClient.get("/survey-questions");
  },

  postSurvey(params) {
    return apiClient.post("/survey-questions", params);
  },

  fetchSurveyCompleteMessage() {
    return apiClient.get("/survey-complete-message");
  },

  //User
  forgottenPassword(params) {
    return apiClient.post("/password/email", params);
  },

  resetPassword(params) {
    return apiClient.post("/password/reset", params);
  },

  login(params) {
    return apiClient.post("/login", params);
  },

  logout() {
    return apiClient.post("/logout");
  },

  requestAccess(params) {
    return apiClient.post("/request-access", params);
  },

  isLoggedIn() {
    return apiClient.head("/api/logged-in");
  },

  tutorialWatched() {
    return apiClient.post("/api/tutorial-watched");
  },

  markIntroVideoAsWatched() {
    return apiClient.post("/api/intro-video-watched");
  },

  postContactForm(params) {
    return apiClient.post("/contact", params);
  },

  //Courses
  getChapters() {
    return apiClient.get("/api/chapters");
  },

  getWeekByWeekNumber(weekNumber) {
    return apiClient.get("/api/course-week/" + weekNumber);
  },

  getCurrentWeek() {
    return apiClient.get("/api/current-week");
  },

  checkAuthorizedToAccessWeekDay(week, day) {
    return apiClient.head(`/api/course-content/${week}/${day}`);
  },

  dayComplete(payload) {
    return apiClient.post("/api/course-day-complete", payload);
  },

  getAppropriateWeekFromCompletedDay(week, day) {
    return apiClient.get(`/api/appropriate-week-from-completed-day/${week}/${day}`);
  },

  postFavouriteCourseContent(favourableId, params) {
    return apiClient.post(`/api/favourite-course-content/${favourableId}`, params);
  },

  deleteFavouriteCourseContent(favourableId) {
    return apiClient.delete(`/api/favourite-course-content/${favourableId}`);
  },

  postCourseReflection(params) {
    return apiClient.post("/api/reflection", params);
  },

  getPreviousReflections(params) {
    return apiClient.get(`/api/reflections/${params.week}/${params.day}`);
  },

  //Library
  getLibraryContent() {
    return apiClient.get("/api/library-content");
  },

  postFavouriteLibraryContent(favourableId) {
    return apiClient.post(`/api/favourite-library-content/${favourableId}`);
  },

  deleteFavouriteLibraryContent(favourableId) {
    return apiClient.delete(`/api/favourite-library-content/${favourableId}`);
  },

  reportBroken(libraryContentId) {
    return apiClient.post(`/api/library-content-broken/${libraryContentId}`);
  },

  //Profile
  postProfilePhoto(formData) {
    return apiClient.post("/api/user/profile-photo", formData);
  },

  getFeelGoodFormula() {
    return apiClient.get("/api/user/feel-good-formula");
  },

  getKnowYourself() {
    return apiClient.get("/api/user/know-yourself");
  },

  postFeelGoodFormula(path, params) {
    return apiClient.post(path, params);
  },

  patchFeelGoodFormula(path, params) {
    return apiClient.patch(path, params);
  },

  postKnowYourself(path, params) {
    return apiClient.post(path, params);
  },

  patchKnowYourself(path, params) {
    return apiClient.patch(path, params);
  },

  getAnnouncementNotifications() {
    return apiClient.get("/api/announcement-notifications");
  },

  getAnnouncementNotificationArticle(notificationId) {
    return apiClient.get(`/api/announcement-notification/${notificationId}`);
  },

  patchAnnouncementNotifications() {
    return apiClient.patch("/api/announcement-notifications");
  },

  getFavourites() {
    return apiClient.get("/api/favourites");
  },

  getProfile() {
    return apiClient.get("/api/profile");
  },

  patchUserName(params) {
    return apiClient.patch("/api/user/name", params);
  },

  patchUserEmail(params) {
    return apiClient.patch("/api/user/email", params);
  },

  patchUserPassword(params) {
    return apiClient.patch("/api/user/password", params);
  },

  getSettings() {
    return apiClient.get("/api/user/settings");
  },

  postSettings(course_notifications) {
    return apiClient.post("/api/user/settings", { course_notifications });
  },

  //progress
  getScores() {
    return apiClient.get("/api/daily-checks/scores");
  },
  getAchievements() {
    return apiClient.get("/api/achievements");
  },
  getNotes() {
    return apiClient.get("/api/notes");
  },
  postNote(params) {
    return apiClient.post("/api/notes", params);
  },
  getDaysAchievements(week, day) {
    return apiClient.get(`/api/achievements/${week}/${day}`);
  },

  siteSearch(params) {
    return apiClient.post("/api/site-search", params);
  },
  getSiteVersion() {
    return apiClient.get("/site-version");
  },
  getCmsPage(cmsPageCode) {
    return apiClient.get(`/cms-page/${cmsPageCode}`);
  }
};
