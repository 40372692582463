import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";
import user from "@/store/user/index";
import survey from "@/store/survey/index";
import course from "@/store/course/index";
import library from "@/store/library/index";
import site from "@/store/site/index";

//don't put course or user data into storage, testers switching between account could get the wrong data retrieved
//if they log back in as a different user after an expired session - rather than using logout
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ["site", "survey"]
});
const vuexSession = new VuexPersistence({
  storage: window.sessionStorage,
  modules: ["library", "user"]
});

export default createStore({
  actions: {
    clearAll({ commit }) {
      commit("user/reset");
      commit("survey/reset");
      commit("course/reset");
      commit("library/reset");
    }
  },
  modules: {
    site,
    survey,
    user,
    course,
    library
  },
  plugins: [vuexLocal.plugin, vuexSession.plugin]
});
