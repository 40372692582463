<template>
  <div>
    <div
      class="w-full md:w-1/2 mx-auto relative read-more-container overflow-hidden px-4 md:px-0"
      ref="readMoreContainer"
    >
      <div ref="innerContainer">
        <a
          href="#"
          ref="readMoreTop"
        ></a>
        <h2
          class="text-2xl font-bold mb-8"
          v-html="cmsBlock.decoded_content.heading"
        ></h2>
        <div
          class="preserve-line-breaks text-lg leading-relaxed"
          v-html="cmsBlock.decoded_content.body"
        ></div>
        <div
          class="mask w-full absolute bottom-0 z-10 h-20"
          :class="{ hidden: readMore }"
        ></div>
      </div>
    </div>
    <p class="text-center">
      <button
        @click.prevent.stop="showMore"
        class="mt-8 transition duration-500 ease-in-out bg-cream-dark hover:bg-white text-dark font-normal py-3 px-8 rounded-full flex-none text-center focus:outline-none"
        :class="{ hidden: readMore }"
      >
        Continue Reading
      </button>
    </p>
  </div>
</template>

<script>
export default {
  name: "ReadMoreText",
  props: {
    cmsBlock: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      readMore: false
    };
  },
  methods: {
    showMore(event) {
      event.preventDefault();
      let that = this;
      let container = this.$refs.readMoreContainer;
      let fullHeight = this.$refs.innerContainer.offsetHeight;
      // this.$refs.readMoreTop.focus();
      this.$nextTick(function () {
        container.setAttribute("style", "max-height:" + fullHeight + "px;");
        that.readMore = true;
      });
      // this.readMore = true;
    }
  }
};
</script>

<style scoped>
.preserve-line-breaks {
  white-space: pre-line;
}

.read-more-container {
  max-height: 500px;
  padding-bottom: 40px;
  transition: max-height 0.66s ease-out;
}

.mask {
  background-image: linear-gradient(to top, rgba(254, 251, 242, 1), rgba(254, 251, 242, 0));
}
</style>
